import React, { useState, useEffect } from 'react';

import axios from 'axios';

import './PageHome.css';


function PageHome(props) {

	// const [main, setMain] = useState([]);
	// useEffect(() => {
	// 	axios({
	// 		method: 'GET',
	// 		url: 'http://gk-kontur.pro/api/main'
	// 	}).then((response) => {
	// 		setMain(response.data)
	// 	});
	// }, []);


	const [postMain, setPostMain] = useState([])

	useEffect(() => {
		axios({
			method: "GET",
			url: `http://gk-kontur.pro/api/blogpost/`
		}).then(response => {
			setPostMain(response.data)
		})
	}, [])



	return (
			<div className="PageHome">
				{/* {main.map((c) => (c.id === 3)?(<h3>{c.name}</h3>) : null)} */}
				<h3>О компании</h3>

				<br />
				{postMain.map((c) => (c.blog_category.id === 3) ? (<div><h3>{c.title}</h3>{c.content} <br /> < br /> </div>) : null)}
			</div>
	);
}

export default PageHome;
