import React, { useState, useEffect } from 'react';

import axios from 'axios';

import './PageServices.css';

function PageServices(props) {


	// const [services, setServices] = useState([]);
	// useEffect(() => {
	// 	axios({
	// 		method: 'GET',
	// 		url: 'http://gk-kontur.pro/api/services',
	// 	}).then((response) => {
	// 		setServices(response.data);
	// 	});
	// }, []);


	const [postServices, setPostServices] = useState([])

	useEffect(() => {
		axios({
			method: "GET",
			url: `http://gk-kontur.pro/api/blogpost/`
		}).then(response => {
			setPostServices(response.data)
		})
	}, [])


	return (
			<div className="PageServices">
				{/* {services.map((c) => (c.id === 4) ? (<h3>{c.name}</h3>) : null)} */}
				<h3>Услуги</h3>
				<br />
				{postServices.map((c) => (c.blog_category.id === 4) ? (<div><h3>{c.title}</h3>{c.content} <br /> < br /> </div>) : null)} 

			</div>
	);
}

export default PageServices;
