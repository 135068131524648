import React, { useState, useEffect } from 'react';

import axios from 'axios';
import HeadShake from 'react-reveal/HeadShake';

function Contacts(props) {

	const [postContact, setPostContact] = useState([])

	useEffect(() => {
		axios({
			method: "GET",
			url: `http://gk-kontur.pro/api/blogpost/`
		}).then(response => {
			setPostContact(response.data)
		})
	}, [])


	return (
		<div>

			<HeadShake>
				<div className={`${props.adaptiveWidth}_contacts`}>

					{postContact.map((c) => (c.title === "телефон") ? (
						<li className={`${props.adaptiveWidth}_phones`}>
							<span>{c.content}</span>
						</li>
					) : null)}

					{postContact.map((c) => (c.title === "e-mail") ? (
						<li className="email">
							<span>{c.content}</span>
						</li>
					) : null)}

					{postContact.map((c) => (c.title === "www") ? (
						<li className="www">
							<span>{c.content}</span>
						</li>
					) : null)}

				</div>
			</HeadShake>

		</div>
	);
}

export default Contacts;
