import React, { useState, useEffect } from 'react';

import axios from 'axios';

import './PageObjects.css';
import p1 from '../../../images/objects/_1.png';
import p2 from '../../../images/objects/_2.png';
import p3 from '../../../images/objects/_3.png';
import p4 from '../../../images/objects/_4.png';
import p5 from '../../../images/objects/_5.png';
import p6 from '../../../images/objects/_6.png';
import p7 from '../../../images/objects/_7.png';
import p8 from '../../../images/objects/_8.png';
import p9 from '../../../images/objects/_9.png';

function PageObjects(props) {


	// const [objects, setObjects] = useState([]);
	// useEffect(() => {
	// 	axios({
	// 		method: 'GET',
	// 		url: 'http://gk-kontur.pro/api/main'
	// 	}).then((response) => {
	// 		setObjects(response.data);
	// 	});
	// }, []);


	const [postObjects, setPostObjects] = useState([])

	useEffect(() => {
		axios({
			method: "GET",
			url: `http://gk-kontur.pro/api/blogpost/`
		}).then(response => {
			setPostObjects(response.data)
		})
	}, [])


	return (
			<div className="PageObjects">
				{/* {objects.map((c) => (c.id === 7) ? (<h3>{c.name}</h3>) : null)} */}
				<h3>Наши работы</h3>

				<br />

				{postObjects.map((c) => (c.blog_category.id === 7) ? (<div><img src={c.image} alt="" /> <br /> <br /> <div>{c.content}</div> <br /> < br /> </div>) : null)} 

			</div>
	);
}

export default PageObjects;