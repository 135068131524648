import React, { useState, useEffect } from 'react';

import './PageSolutions.css';

import axios from 'axios';

function PageSolutions(props) {

	// const [solutions, setSolutions] = useState([]);
	// useEffect(() => {
	// 	axios({
	// 		method: 'GET',
	// 		url: 'http://gk-kontur.pro/api/solutions'
	// 	}).then((response) => {
	// 		setSolutions(response.data);
	// 	});
	// }, []);

	const [postSolutions, setPostSolutions] = useState([])

	useEffect(() => {
		axios({
			method: "GET",
			url: `http://gk-kontur.pro/api/blogpost/`
		}).then(response => {
			setPostSolutions(response.data)
		})
	}, [])



	return (
			<div className="PageSolutions">
				{/* {solutions.map((c) => (c.id === 5)?(<h3>{c.name}</h3>) : null)} */}
				<h3>Решения</h3>
				<br />
				{postSolutions.map((c) => (c.blog_category.id === 5) ? (<div><h3>{c.title}</h3>{c.content} <br /> < br /> </div>) : null)}
			</div>
	);
}

export default PageSolutions;
