import React, { useState, useEffect } from 'react';

import axios from 'axios';

import './PageTechnologies.css';

function PageTechnologies(props) {


		// const [technologies, setTechnologies] = useState([]);
		// useEffect(() => {
		// 	axios({
		// 		method: 'GET',
		// 		url: 'http://gk-kontur.pro/api/main'
		// 	}).then((response) => {
		// 		setTechnologies(response.data);
		// 	});
		// }, []);

		const [postTechnologies, setPostTechnologies] = useState([])
	
		useEffect(() => {
			axios({
				method: "GET",
				url: `http://gk-kontur.pro/api/blogpost/`
			}).then(response => {
				setPostTechnologies(response.data)
			})
		}, [])


	return (
			<div className="PageTechnologies">
				{/* {technologies.map((c) => (c.id === 6)?(<h3>{c.name}</h3>) : null)} */}
				<h3>Технологии</h3>
				<br />
				{postTechnologies.map((c) => (c.blog_category.id === 6) ? (<div><h3>{c.title}</h3>{c.content} <br /> < br /> </div>) : null)}
			</div>
	);
}

export default PageTechnologies;
