import React, { useState, useEffect } from 'react';

import axios from 'axios';
import './PageAbout.css';

function PageAbout(props) {


	// const [contact, setContact] = useState([]);
	// useEffect(() => {
	// 	axios({
	// 		method: 'GET',
	// 		url: 'http://gk-kontur.pro/api/contact'
	// 	}).then((response) => {
	// 		setContact(response.data);
	// 	});
	// }, []);


	const [postContact, setPostContact] = useState([])

	useEffect(() => {
		axios({
			method: "GET",
			url: `http://gk-kontur.pro/api/blogpost/`
		}).then(response => {
			setPostContact(response.data)
		})
	}, [])

	
	return (
			<div className="PageAbout">
				<br />
				{/* {contact.map((c) => (c.id === 8)?(<h3>{c.name}</h3>) : <h3>Контакты</h3>)} */}
				<h3>Контакты</h3>
				<br />
				{postContact.map((c) => (c.blog_category.id === 8) ? (<div>					
					<li>
						<span>{c.content}</span>
					</li>
					
					</div>) : null)} 

				<br />
				<br />
				<br />
				<h3>Время работы</h3>
				<br />
				<div>
					Понедельник - Пятница: 10:00 до 19:00
					<br />
					Суббота - Воскресенье - Выходной
				</div>
				<br />
			</div>
	);
}

export default PageAbout;
